<template>
    <v-card>
        <v-col cols="12" class="px-1">
            <v-row no-gutters>
                <v-col cols="6" v-if="user && false">
                    <user-checkin-card :user="user"></user-checkin-card>
                </v-col>
                <v-col cols="6" v-if="user">
                    <user-weight-card @newTargetWeight="newTargetWeight" :user="user"></user-weight-card>
                </v-col>
            </v-row>
            <!--<v-row no-gutters v-if="false">
                <v-col cols="12">
                    <v-card>
                        <v-toolbar flat>
                            <v-btn icon @click="prevWeek">
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>

                            <v-toolbar-title>{{ formattedDate }}</v-toolbar-title>
                            <v-btn icon @click="nextWeek">
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-divider></v-divider>
                        <v-row class="days-header" no-gutters>
                            <v-col v-for="day in dayNames" :key="day" class="text-center">
                                <strong>{{ day }}</strong>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="pa-4" no-gutters>
                            <v-col v-for="(day, index) in weekDays" :key="index" cols="1" class="day-cell">
                                <div class="day-number">{{ day.date.split('-')[2] }}</div>
                                <v-icon v-if="day.hasEvent" color="green" small class="event-dot">mdi-circle</v-icon>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row> -->
        </v-col>
    </v-card>
</template> 

<script>
import UserWeightCard from '@/views/user/components/UserWeightCard.vue'
import UserCheckinCard from '@/views/user/components/UserCheckinCard.vue'

export default {
    components: {
        UserWeightCard,
        UserCheckinCard,
    },
    props: {
        user: {
            type: Object,
        },
    },
    data() {
        return {
            currentWeekStart: this.$moment().startOf('week'),
            events: {
                // Beispiel: { 'YYYY-MM-DD': true }
                '2024-08-26': true,
                '2024-08-29': true,
            },
            dayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        }
    },
    computed: {
        weekDays() {
            let days = []
            for (let i = 0; i < 7; i++) {
                let date = this.$moment(this.currentWeekStart).add(i, 'days')
                days.push({
                    date: date.format('YYYY-MM-DD'),
                    hasEvent: !!this.events[date.format('YYYY-MM-DD')],
                })
            }
            return days
        },
        formattedDate() {
            return `${this.currentWeekStart.format('MMM D')} - ${this.$moment(this.currentWeekStart)
                .add(6, 'days')
                .format('MMM D')}, ${this.currentWeekStart.format('YYYY')}`
        },
    },
    methods: {
        prevWeek() {
            this.currentWeekStart = this.currentWeekStart.subtract(1, 'week')
        },
        nextWeek() {
            this.currentWeekStart = this.currentWeekStart.add(1, 'week')
        },
        newTargetWeight(val) {
            this.user.targetWeight = val
        },
    },
}
</script>
<style scoped>
.days-header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
}

.day-cell {
    height: 100px;
    position: relative;
    border: 1px solid #ddd;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
}

.day-number {
    font-size: 20px;
    font-weight: bold;
}

.event-dot {
    position: absolute;
    bottom: 10px;
    right: 10px;
}
</style>