<template>
    <v-card flat>
        <v-row v-if="loading" no-gutters>
            <v-col class="mt-2" cols="12" align="center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
        </v-row>
        <v-row v-else-if="!startedVerification" no-gutters>
            <v-col v-if="!verified" cols="12">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-card-text>
                            Um dieses Feature nutzen zu können, ist eine Bestätigung deiner E-Mail-Adresse erforderlich.
                            Bitte folge diesen Schritten:
                            <ol>
                                <li>
                                    Fordere einen Bestätigungscode an, der an deine E-Mail-Adresse ({{ email }})
                                    gesendet wird.
                                </li>
                                <li>Gib diesen Code innerhalb von 15 Minuten in das untenstehende Feld ein.</li>
                                <li>
                                    Solltest du den Code nicht innerhalb dieser Zeit eingeben können, kannst du einen
                                    neuen Code anfordern.
                                </li>
                            </ol>
                            Durch die Bestätigung deiner E-Mail-Adresse stellst du sicher, dass dein Konto sicher und
                            geschützt bleibt.
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" align="center">
                        <v-btn @click="startVerification" color="primary">Code anfordern</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-else-if="!timeoutVerfiying" no-gutters>
            <v-col cols="12" class="text-center my-2">
                <v-progress-circular
                    :size="100"
                    :width="15"
                    :value="(timeRemaining / timerDuration) * 100"
                    color="primary"
                >
                    {{ Math.floor(timeRemaining / 60) }}:{{ ('0' + (timeRemaining % 60)).slice(-2) }}
                </v-progress-circular>
                <v-col align="center" cols="12">
                    <v-btn text :disabled="timeRemaining >= 600" @click="startVerification" color="primary">
                        Neuen Code anfordern (nach 5 Minuten)
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <v-alert class="mb-0" type="info">Bitte prüfe auch den Spam-Ordner</v-alert>
                </v-col>
                <v-card-text>
                    Gib den Code, den wir an {{ email }} geschickt haben, in das untenstehende Feld ein:
                </v-card-text>
            </v-col>
            <v-col align="center" cols="12">
                <v-text-field
                    style="max-width: 250px"
                    label="Verifizierungscode"
                    v-model="verificationCode"
                    :rules="[(v) => v.length === 6 || 'Der Code muss genau 6 Zeichen lang sein']"
                    outlined
                    type="number"
                ></v-text-field>
            </v-col>
            <v-col align="center" cols="12">
                <v-btn color="primary" @click="confirmCode">Bestätigen</v-btn>
            </v-col>
        </v-row>
        <v-row v-else no-gutters>
            <v-col cols="12">
                <v-card-text>
                    Die Zeit für die Eingabe des Verifizierungscodes ist leider abgelaufen. Bitte fordere einen neuen
                    Code an, um den Prozess der E-Mail-Verifizierung abzuschließen.
                </v-card-text>
            </v-col>
            <v-col cols="12" align="center">
                <v-btn @click="startVerification" color="primary">Neuen Code anfordern</v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import api from '@/api'
export default {
    data() {
        return {
            verified: false,
            email: null,
            loading: true,
            startedVerification: false,
            // Startzeit des Timers, z.B. aus der Datenbank oder vom Server geladen
            verificationStartDate: new Date(),
            // Timer-Dauer in Sekunden (5 Minuten = 300 Sekunden)
            timerDuration: 900,
            // Aktuelle verbleibende Zeit
            timeRemaining: 900,
            // Verifizierungscode, den der Benutzer eingibt
            verificationCode: '',
            interval: null,
            timeoutVerfiying: false,
        }
    },
    created() {
        this.fetchVerifiedStatus()
    },
    beforeDestroy() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    },
    methods: {
        startCountdown() {
            if (this.interval) {
                clearInterval(this.interval)
            }
            this.timeoutVerfiying = false
            this.verificationStartDate = new Date()
            this.interval = setInterval(() => {
                const now = new Date()
                const diffInSeconds = Math.floor((now - this.verificationStartDate) / 1000)
                this.timeRemaining = this.timerDuration - diffInSeconds

                if (this.timeRemaining <= 0) {
                    clearInterval(this.interval)
                    this.timeoutVerfiying = true
                    // Hier kannst du Aktionen durchführen, wenn die Zeit abgelaufen ist
                }
            }, 1000)
        },
        async confirmCode() {
            this.timeoutVerfiying = false
            // Überprüfen, ob ein Code eingegeben wurde
            if (!this.verificationCode) {
                return this.$toast.error('Bitte gib einen Code ein.')
            }

            // Überprüfen, ob der Code genau 6 Ziffern lang ist
            if (this.verificationCode.length !== 6) {
                return this.$toast.error('Der Code muss genau 6 Ziffern lang sein.')
            }

            // Überprüfen, ob der Code nur Zahlen enthält
            if (!/^\d+$/.test(this.verificationCode)) {
                return this.$toast.error('Der Code darf nur Zahlen enthalten.')
            }

            try {
                let status = await api.verifyEmailVerificationCode({ code: this.verificationCode })

                if (status.verified) {
                    this.$toast.success('E-Mail erfolgreich verifiziert')
                    this.$emit('emailVerified')
                } else {
                    this.$toast.error('Code ungültig')
                }
            } catch (e) {
                this.$toast.error('Es gab ein Problem, bitte versuche es später erneut')
            }
        },
        async startVerification() {
            this.$emit('verifying')
            this.startedVerification = true
            this.timeRemaining = this.timerDuration
            try {
                let status = await api.startEmailVerification()
                if (status.created) {
                    this.$toast.success('Code per E-Mail versendet')
                    this.startCountdown()
                } else {
                    this.startedVerification = false
                    this.$toast.error('Es gab einen Fehler, bitte versuche es erneut')
                }
            } catch (e) {
                this.startedVerification = false
                this.$toast.error('Es gab einen Fehler, bitte versuche es erneut')
            }
        },
        async fetchVerifiedStatus() {
            this.loading = true
            console.log('hioasfölkasdjfklsd')
            try {
                let status = await api.fetchUserEmailVerificationStatus()
                this.verified = status.verified
                this.email = status.email
                console.log('da isch det status', status.verified)
                status.verified ? this.$emit('emailVerified') : this.$emit('emailNotVerified')
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim laden des Status')
            }
            this.loading = false
        },
    },
}
</script>