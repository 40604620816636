<!-- Payment.vue -->
<template>
    <div class="d-flex justify-center">
        <v-btn
            @click="$router.push('/user/purchases')"
            x-large
            color="primary"
            outlined
            max-width="400px"
            class="mx-auto my-5"
            v-if="purchases.length"
        >
            <v-row>
                <v-col cols="12" class="d-flex justify-center align-center py-7">
                    <v-icon>mdi-cash</v-icon>
                    <span class="my-auto mx-5 font-weight-bold">Deine Einkäufe ({{ purchases.length }})</span>
                    <v-icon>mdi-chevron-right</v-icon>
                </v-col>
            </v-row>
        </v-btn>
    </div>
</template>
   
<script>
import axios from 'axios'
import api from '@/api'
import userService from '@/services/userService'

export default {
    data() {
        return {
            purchases: [],
        }
    },
    async created() {
        this.purchases = await api.fetchAllPurchases()
    },
    computed: {},
}
</script>
  