<template>
    <div class="text-center">
        <v-menu min-width="330px" v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="actionButton" x-large icon v-bind="attrs" v-on="on">
                    <v-icon color="#343434">mdi-dots-horizontal</v-icon>
                </v-btn>
            </template>

            <v-card>
                <v-list class="pb-0">
                    <v-list-item v-if="user">
                        <v-list-item-avatar>
                            <img v-if="user.bild" :src="'/img/profilbilder' + user.bild" alt="" />
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title class="font-weight-bold">
                                {{ user.vorname }} {{ user.nachname }}
                            </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-btn icon @click="logout">
                                <v-icon>mdi-logout-variant</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-list v-if="items">
                    <v-list-item-group color="primary">
                        <v-list-item
                            @click="runFunction(item)"
                            v-for="(item, i) in items.filter((item) => {
                                if (!item.hasRights) return item
                                if (
                                    item.hasRights &&
                                    ((user.userRoles && user.userRoles.length) ||
                                        (user.specialRights && user.specialRights.length))
                                )
                                    return item
                            })"
                            :key="i"
                        >
                            <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-action>
                            <v-switch
                                :loading="!mailSubscriptionInfoFetched"
                                :disabled="!mailSubscriptionInfoFetched"
                                @change="changeEmailSubscription"
                                v-model="emailMessagesEnabled"
                                color="primary"
                            ></v-switch>
                        </v-list-item-action>
                        <v-list-item-title>E-Mail Benachrichtigungen</v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-action>
                            <v-switch
                                :loading="!newsletterInfoFetched"
                                v-model="newsletterEnabled"
                                @change="changeNewsletterStatus"
                                :disabled="!newsletterInfoFetched"
                                color="primary"
                            ></v-switch>
                        </v-list-item-action>
                        <v-list-item-title>Newsletter {{ newsletterStudio }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="false">
                        <v-list-item-action>
                            <v-switch v-model="pushEnabled" color="primary"></v-switch>
                        </v-list-item-action>
                        <v-list-item-title>Push Benachrichtigungen</v-list-item-title>
                    </v-list-item>
                </v-list>

                <v-card-actions v-if="false">
                    <v-spacer></v-spacer>

                    <v-btn text @click="menu = false">Cancel</v-btn>
                    <v-btn color="primary" text @click="menu = false">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
        <anamnesebogen ref="anamnesebogen"></anamnesebogen>
        <anamnese-bogen-create @closeAnamnesebogen="closeAnamnesebogen" ref="anamneseBogenRef"></anamnese-bogen-create>
        <newsletter-dialog
            :user="user"
            @close="showNewsletterDialog = false"
            @newsletterSubscribed="fetchNewsletterInfo"
            :active="showNewsletterDialog"
        ></newsletter-dialog>
        <v-dialog v-model="nonDisclosureAgreementVal">
            <non-disclosure-agreement withoutSignature></non-disclosure-agreement>
        </v-dialog>
    </div>
</template>

<script>
import nonDisclosureAgreement from '@/components/nonDisclosureAgreement/nonDisclosureAgreement'
import anamneseBogenCreate from '@/components/anamneseBogenCreate'
import Anamnesebogen from '@/components/Anamnesebogen'
import NewsletterDialog from '@/components/NewsletterDialog'
import { mapGetters, mapActions, mapState } from 'vuex'
import api from '@/api'
export default {
    name: 'UserMenu',
    props: {
        user: {
            type: Object,
            default: null,
        },
    },
    components: {
        anamneseBogenCreate,
        Anamnesebogen,
        NewsletterDialog,
        nonDisclosureAgreement,
    },
    data() {
        return {
            nonDisclosureAgreementVal: false,
            items: [
                { text: 'Meine Daten', icon: 'mdi-account', routerLink: '/user/edit' },
                { text: 'Datenschutz', icon: 'mdi-note-text-outline', routerLink: '/datenschutz' },
                { text: 'Geheimhaltungserklärung', hasRights: true, icon: 'mdi-note-text-outline' },
                { text: 'Zahlungen', icon: 'mdi-cash-clock', hasRights: true, routerLink: '/user/payments' },
                { text: 'Mein Anamnesebogen', icon: 'mdi-alpha-a-box' },
            ],
            showNewsletterDialog: false,
            menu: false,
            pushEnabled: true,
            newsletterEnabled: false,
            emailMessagesEnabled: true,
            newsletterStudio: '',
            newsletterInfoFetched: false,
            mailSubscriptionInfoFetched: true,
        }
    },
    created() {
        this.fetchNewsletterInfo()

        console.log(this.user.notification_email, 'HERE')
        this.emailMessagesEnabled = this.user.notification_email
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', [
            'toggleNavigationBarVisibility',
            'setNavigationBarText',
            'setNavigationbarVisibility',
        ]),
        logout() {
            if (confirm('Möchtest du dich wirklich ausloggen?') === false) return
            localStorage.removeItem('token')
            localStorage.removeItem('user')

            location.reload()
        },
        async changeEmailSubscription(val) {
            this.mailSubscriptionInfoFetched = false
            try {
                if (val) {
                    let status = await api.subscribeEmailSubscription()
                    this.$toast.success('Erfolgreich aktiviert')
                } else {
                    let status = await api.unsubscribeEmailSubscription()
                    this.$toast.success('Erfolgreich deaktiviert')
                }
            } catch (e) {
                this.$toast.error('Es gab ein Problem, bitte versuche es später erneut')
            }
            this.mailSubscriptionInfoFetched = true
        },
        changeNewsletterStatus(val) {
            if (this.newsletterInfoFetched) {
                if (!val) this.unsubscribeMailchimp()
                else {
                    this.showNewsletterDialog = true
                }
            }
        },
        async unsubscribeMailchimp() {
            this.showNewsletterDialog = false
            try {
                this.newsletterInfoFetched = false
                let status = await api.unsubscribeNewsletter()
                console.log(status)
                this.$toast.success('Erfolgreich abgemeldet')
                this.fetchNewsletterInfo()
            } catch (e) {
                this.$toast.error('Fehler beim Abmelden vom Newsletter')
            }
        },
        async fetchNewsletterInfo() {
            this.newsletterInfoFetched = false
            this.showNewsletterDialog = false
            try {
                let status = await api.fetchNewsletterInfo()
                if (status.status === 'subscribed') {
                    this.newsletterEnabled = true
                    if (status.merge_fields['MMERGE8']) this.newsletterStudio = status.merge_fields['MMERGE8']
                } else if (status.status === 'unsubscribed') {
                    this.newsletterEnabled = false
                    this.newsletterStudio = 'abgemeldet'
                } else {
                    this.newsletterEnabled = false
                    this.newsletterStudio = ''
                }
                this.newsletterInfoFetched = true
            } catch (e) {
                console.log('ERROR')
                this.newsletterEnabled = false
                this.newsletterStudio = ''
                this.newsletterInfoFetched = true
            }
        },
        closeAnamnesebogen() {
            if (this.navigationVisible) this.toggleNavigationBarVisibility()
        },
        runFunction(item) {
            if (item.text === 'Mein Anamnesebogen') {
                this.showAnamnesebogen()
            } else if (item.text === 'Geheimhaltungserklärung') {
                this.nonDisclosureAgreementVal = true
            } else if (item.routerLink) {
                this.$router.push(item.routerLink)
            } else {
                return false
            }
        },

        showAnamnesebogen() {
            api.getUserAnamnesebogen()
                .then((data) => {
                    if (data.status == 2) {
                        alert('Es wurde noch kein Anamnesebogen hinterlegt')
                    } else {
                        if (data.date) {
                            this.$refs['anamnesebogen'].show(data, 1)
                        } else {
                            this.$refs.anamneseBogenRef.setVisible(data._id)
                        }
                    }
                })
                .catch((error) => {})
        },
    },
    computed: {
        ...mapState('navigationStoreNotPersisted', ['navigationItems', 'navigationVisible']),
    },
    watch: {},
}
</script>

<style lang="scss" scoped>
.actionButton.v-btn--outlined {
    border: thin solid #8888888a;
}
</style>