<template>
    <v-card outlined class="d-flex flex-column pa-1 rounded-lg fill-height" @click="toggleWeightDialog()">
        <user-statistics-weight-dialog
            @newTargetWeight="newTargetWeight"
            v-if="weightDialog"
            :user="user"
            @close="toggleWeightDialog"></user-statistics-weight-dialog>
        <span style="margin-bottom: -20px" class="font-weight-bold grey--text mx-auto">Gewicht</span>
        <apexchart class="ma-auto rounded-0" :options="weightChartOptions" :series="weightChartItems"></apexchart>
        <div class="mx-auto"><number class="h5 mb-0" :from="0" :to="userWeight"></number><span>KG</span></div>
    </v-card>
</template>

<script>
import Slim from '@/plugins/slimImage/slim/slim.vue'
import api from '@/api'
import UserStatisticsWeightDialog from './UserStatisticsWeightDialog.vue'
export default {
    name: 'UserWeightCard',
    data() {
        return {
            weightDialog: false,
        }
    },
    props: {
        user: {
            type: Object,
        },
    },
    components: { UserStatisticsWeightDialog },
    methods: {
        toggleWeightDialog() {
            this.weightDialog = !this.weightDialog
        },
        newTargetWeight(val) {
            this.$emit('newTargetWeight', val)
        },
    },
    computed: {
        weightChartItems() {
            let data = []
            if (this.user.gewicht) data.push(this.user.gewicht)
            if (this.user.weights && this.user.weights.length) {
                this.user.weights.forEach((weight) => {
                    data.push(weight.value)
                })
            }
            if (this.targetWeight) data.push(this.targetWeight)
            return [
                {
                    name: 'Gewicht',
                    data,
                },
            ]
        },
        userWeight() {
            if (this.user.weights && this.user.weights.length) return this.user.weights[this.user.weights.length - 1].value
            if (this.user.gewicht) {
                return this.user.gewicht
            }
            return 0
        },
        weightChartOptions() {
            return {
                chart: {
                    toolbar: {
                        show: false,
                    },
                    type: 'area',
                },
                grid: {
                    show: false,
                },
                markers: {
                    size: 3,
                },
                stroke: {
                    width: 1,
                },
                dataLabels: {
                    enabled: false,
                },
                yaxis: {
                    show: false,
                },
                xaxis: {
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                colors: [
                    this.$vuetify.theme.themes.light.primary,
                    this.$vuetify.theme.themes.light.darkenprimary1,
                    this.$vuetify.theme.themes.light.darkenprimary2,
                ],
            }
        },
    },
}
</script>
<style lang="scss" scoped>
</style>