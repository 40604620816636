<template>
    <v-dialog persistent v-model="showDialog">
        <v-card>
            <v-card-title class="primary white--text">Krankenkassen Bestätigung</v-card-title>
            <v-card-actions>
                <v-btn text @click="closeForm()">Schliessen</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from '@/api'

export default {
    props: {
        showDialog: {
            type: Boolean,
        },
    },
    data() {
        return {
            customerInfos: null,
            loadingTACContract: true,
            error: false,
            contract: null, // Hinzugefügt
            allExistingContracts: null, // Hinzugefügt
            transactions: null, // Hinzugefügt
            fixTermTimesMissingBefore: [],
            fixTermTimesMissingAfter: [],
            allTransactions: [], // Hinzugefügt
            loadingTACContract: false,
            contractEndDate: null,
            allIdlePeriods: [], // Hinzugefügt, falls später im Code verwendet wird
            contractStartDate: null,
            contractInformations: null, // Hinzugefügt
        }
    },
    created() {
        this.init()
        this.fetchContracts()
    },
    methods: {
        closeForm() {
            this.$emit('closeForm')
        },
        async fetchContracts() {
            let error = false
            let contract = null
            let transactions = []
            let allExistingContracts = []
            try {
                contract = await api.fetchTACMemberOwnContract()

                allExistingContracts = await api.fetchTACAllMemberships()
                if (allExistingContracts?.MemberDetails.MemberDetail) {
                    let vipImport = allExistingContracts.MemberDetails.MemberDetail.find((detail) => {
                        return (
                            detail.MemberCategoryName === 'VIP Import' &&
                            detail.ContractID.UniqueID[0].value !== contract.MemberDetail.ContractID.UniqueID[0].value
                        )
                    })
                    if (vipImport) {
                        this.fixTermTimesMissingBefore = this.fixTermTimesMissingBefore.concat(
                            vipImport.ContractTermTimes.ContractTermTime
                        )
                    }

                    let sameContractTermTimes = allExistingContracts.MemberDetails.MemberDetail

                    if (sameContractTermTimes?.length) {
                        for (let sameContract of sameContractTermTimes) {
                            if (
                                contract.MemberDetail.ContractTermTimes.ContractTermTime.find(
                                    (contTime) =>
                                        contTime.TimeSpan.Start ===
                                        sameContract.ContractTermTimes.ContractTermTime[0].TimeSpan.Start
                                )
                            ) {
                                continue
                            }
                            if (
                                contract.MemberDetail.ContractID.UniqueID[0].value ===
                                sameContract.ContractID.UniqueID[0].value
                            ) {
                                continue
                            }

                            if (
                                this.$moment(
                                    sameContract.ContractTermTimes.ContractTermTime[0].TimeSpan.Start,
                                    'YYYY-MM-DD'
                                ).isBefore(
                                    this.$moment(
                                        contract.MemberDetail.ContractTermTimes.ContractTermTime[0].TimeSpan.Start,
                                        'YYYY-MM-DD'
                                    )
                                )
                            ) {
                                this.fixTermTimesMissingBefore = this.fixTermTimesMissingBefore.concat(
                                    sameContract.ContractTermTimes.ContractTermTime
                                )
                            } else {
                                this.fixTermTimesMissingAfter = sameContract.ContractTermTimes.ContractTermTime
                            }
                        }
                    }
                }
            } catch (e) {
                error = true
                console.log(e)
                alert(
                    'Wir konnten leider keine Mitgliedschaft für dich finden. Es kann sein, dass wir deine Mitgliedschaft mit einer anderen E-Mail Adresse hinterlegt haben. Bitte wende dich an das Personal um dies zu prüfen.'
                )
                this.$router.push('/user')
            }

            try {
                if (!error) {
                    transactions = await api.fetchAccountTransactionsForIDAdminTESTING(
                        contract.MemberDetail.Accounts.Account.find((acc) => acc.type === 'MEMBERSHIP').AccountID
                            .UniqueID[0].value,
                        this.$moment('2000-01-01', 'YYYY-MM-DD').format(),
                        this.$moment().add(4, 'years').format()
                    )
                }
            } catch (e) {
                await alert(
                    'Wir konnten leider keine Zahlungen für deine Mitgliedschaft finden. Falls dieses Problem trotz Zahlung auftritt, wende dich bitte an das Personal. '
                )
                this.$router.push('/user')
            }

            transactions.AccountTransactions.AccountTransactions.forEach((transaction) => {
                if (transaction.Type === 'IDLE_PERIOD') {
                    this.allTransactions.push({
                        start: transaction.IdlePeriodTimeRange.Start,
                        end: transaction.IdlePeriodTimeRange.End,
                        cost: transaction.Value,
                        state: transaction.State,
                        type: transaction.Type,
                        mainType: transaction.MainType,
                        det: transaction.AccountTransactionDetails,
                    })
                } else if (transaction.AccountTransactionDetails.AccountTransactionDetail[0].TimeRange) {
                    if (
                        transaction.AccountTransactionDetails.AccountTransactionDetail.find(
                            (det) => det.Description === 'Einschreibgebühr'
                        ) &&
                        !transaction.AccountTransactionDetails.AccountTransactionDetail.find(
                            (det) => det.Description === 'Erlass Fr. 99.00 Einschreibegebühr'
                        )
                    ) {
                        if (transaction.Value.value) {
                            transaction.Value.value -= 99
                        } else {
                            transaction.Value -= 99
                        }
                    }

                    if (
                        transaction.AccountTransactionDetails.AccountTransactionDetail.find((det) =>
                            det.Description.includes('3 Jahres Abonnement')
                        )
                    ) {
                        if (transaction.Value.value) {
                            transaction.Value.value = Number(transaction.Value.value) + 570
                        } else {
                            transaction.Value = Number(transaction.Value) + 570
                        }
                    }
                    let timeRange = transaction.AccountTransactionDetails.AccountTransactionDetail[0].TimeRange
                    this.allTransactions.push({
                        start: timeRange.Start,
                        end: timeRange.End,
                        cost: transaction.Value,
                        state: transaction.State,
                        type: transaction.Type,
                        mainType: transaction.MainType,
                    })
                }
            })

            this.contractInformations = JSON.parse(JSON.stringify(contract.MemberDetail))

            let timeSpans = contract.MemberDetail.ContractTermTimes.ContractTermTime

            let endTimeSpan = contract.MemberDetail.ContractTermTimes.ContractTermTime[timeSpans.length - 1]
            this.contractEndDate = this.$moment(endTimeSpan.TimeSpan.End, 'YYYY-MM-DD').format('DD.MM.YYYY')
            this.contractStartDate = this.$moment(
                timeSpans.reverse().find((ts) => ts.Type === 'DEFAULT').TimeSpan.Start,
                'YYYY-MM-DD'
            ).format('DD.MM.YYYY')
            this.loadingTACContract = false
        },
        async init() {
            let customer = await api.getOwnUserForManager()

            let customerEntry = {
                selection: customer._id,
                gender: customer.geschlecht,
                firstName: customer.vorname,
                lastName: customer.nachname,
                email: customer.email,
                phone: customer.telefon,
                birthday: customer.geburtstag,
                street: customer.adresse_1,
                city: customer.stadt,
                zip: customer.plz,
                signature: null,
            }
            this.customerInfos = customerEntry
        },
        bonusCalculatedFromMultipleTimespans(allTimeSpans, timeSpanDays) {
            const totalDays = allTimeSpans.reduce((total, timeSpan) => {
                return total + this.daysInTimeSpan(timeSpan)
            }, 0)

            return (
                Number(totalDays) === Number(timeSpanDays) ||
                Number(totalDays) - 1 === Number(timeSpanDays) ||
                Number(totalDays) + 1 === Number(timeSpanDays)
            )
        },
        daysInTimeSpan(timeSpan) {
            const start = this.$moment(timeSpan.TimeSpan.Start, 'YYYY-MM-DD')
            const end = this.$moment(timeSpan.TimeSpan.End, 'YYYY-MM-DD')
            return end.diff(start, 'days')
        },
    },
    computed: {
        contractTimespans() {
            if (!this.contractInformations) return []
            let timeSpans = this.contractInformations.ContractTermTimes.ContractTermTime
            // console.log('before', this.fixTermTimesMissingBefore)
            //console.log('after', this.fixTermTimesMissingAfter)
            timeSpans = this.fixTermTimesMissingBefore.concat(timeSpans, this.fixTermTimesMissingAfter)

            //  console.log('am schluss gits: ', timeSpans)
            let activeContractDate = JSON.parse(JSON.stringify(timeSpans))
                .reverse()
                .find(
                    (ts) =>
                        ts.Type === 'DEFAULT' &&
                        !this.$moment(ts.TimeSpan.Start, 'YYYY-MM-DD').isAfter(this.$moment(), 'day')
                ).TimeSpan.Start

            let contracts = []
            let newContract = null
            let idlePeriodTimeSpans = timeSpans.filter((timespan) => timespan.Type === 'IDLEPERIOD')
            timeSpans.forEach((timespan) => {
                let filteredTimeSpans = JSON.parse(JSON.stringify(idlePeriodTimeSpans))
                if (newContract) {
                    filteredTimeSpans = filteredTimeSpans.filter((filterTimeSpan) => {
                        return this.$moment(filterTimeSpan.TimeSpan.Start).isSameOrAfter(
                            this.$moment(newContract.start, 'DD.MM.YYYY')
                        )
                    })
                }
                if (timespan.Type === 'DEFAULT') {
                    if (
                        newContract &&
                        idlePeriodTimeSpans.length &&
                        (idlePeriodTimeSpans.find((idleTimeSpan) => {
                            return this.daysInTimeSpan(idleTimeSpan) === this.daysInTimeSpan(timespan)
                        }) ||
                            this.bonusCalculatedFromMultipleTimespans(filteredTimeSpans, this.daysInTimeSpan(timespan)))
                    ) {
                        console.log('etztchumi ufzmol nümm do ine')
                        newContract.timeStopBonus.push({
                            start: this.$moment(timespan.TimeSpan.Start, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                            end: this.$moment(timespan.TimeSpan.End, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                        })
                    } else {
                        if (newContract !== null) contracts.push(newContract)
                        newContract = {
                            ContractID: timespan.ContractID.UniqueID[0].value,
                            title: this.contractInformations.MemberCategoryName,
                            start: this.$moment(timespan.TimeSpan.Start, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                            end: this.$moment(timespan.TimeSpan.End, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                            bonus: [],
                            timeStopBonus: [],
                            idlePeriods: [],
                            transactions:
                                this.allTransactions.filter(
                                    (transaction) =>
                                        this.$moment(transaction.start, 'YYYY-MM-DD').isSameOrAfter(
                                            this.$moment(timespan.TimeSpan.Start, 'YYYY-MM-DD')
                                        ) &&
                                        this.$moment(transaction.end, 'YYYY-MM-DD').isSameOrBefore(
                                            this.$moment(timespan.TimeSpan.End, 'YYYY-MM-DD')
                                        ) &&
                                        transaction.type !== 'IDLE_PERIOD'
                                ) || [],
                            active: activeContractDate === timespan.TimeSpan.Start,
                            future: this.$moment(timespan.TimeSpan.Start, 'YYYY-MM-DD').isAfter(this.$moment()),
                        }
                    }
                }
                if (timespan.Type === 'BONUSPERIOD') {
                    newContract.bonus.push({
                        start: this.$moment(timespan.TimeSpan.Start, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                        end: this.$moment(timespan.TimeSpan.End, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                    })
                }
            })
            contracts.push(newContract)
            idlePeriodTimeSpans.forEach((timespan) => {
                let foundContract = contracts.find((cont) => {
                    let contEndDate = cont.timeStopBonus.length
                        ? cont.timeStopBonus[cont.timeStopBonus.length - 1].end
                        : cont.bonus.length
                        ? cont.bonus[cont.bonus.length - 1].end
                        : cont.end
                    return this.$moment(timespan.TimeSpan.Start, 'YYYY-MM-DD').isBetween(
                        this.$moment(cont.start, 'DD.MM.YYYY'),
                        this.$moment(contEndDate, 'DD.MM.YYYY')
                    )
                })

                if (!foundContract) return

                this.allIdlePeriods.push({
                    start: this.$moment(timespan.TimeSpan.Start, 'YYYY-MM-DD'),
                    end: this.$moment(timespan.TimeSpan.End, 'YYYY-MM-DD'),
                })
                foundContract.idlePeriods.push({
                    transactions:
                        this.allTransactions.filter(
                            (transaction) =>
                                this.$moment(transaction.start, 'YYYY-MM-DD').isSameOrAfter(
                                    this.$moment(timespan.TimeSpan.Start, 'YYYY-MM-DD')
                                ) &&
                                this.$moment(transaction.end, 'YYYY-MM-DD').isSameOrBefore(
                                    this.$moment(timespan.TimeSpan.End, 'YYYY-MM-DD')
                                )
                        ) || [],
                    start: this.$moment(timespan.TimeSpan.Start, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                    end: this.$moment(timespan.TimeSpan.End, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                })
                this.existingTimestops.push({
                    start: this.$moment(timespan.TimeSpan.Start, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                    end: this.$moment(timespan.TimeSpan.End, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                })
            })
            if (!contracts.length && newContract && newContract.title) contracts.push(newContract)

            if (!contracts.find((cont) => cont.active && !cont.future)) contracts[contracts.length - 1].active = true
            return contracts
        },
    },
}
</script>
