<template>
    <v-col cols="12">
        <v-card class="rounded-lg ma-1" flat>
            <v-row no-gutters class="ma-0 justify-center">
                <v-col cols="3">
                    <v-row no-gutters>
                        <v-col class="d-flex justify-center pb-0" cols="12">
                            <span class="font-weight-bold" style="font-size: 15px">
                                {{ userStats.planDone }}
                            </span>
                        </v-col>
                        <v-col class="d-flex justify-center pt-0" cols="12">
                            <span style="font-size: 12px">Trainings</span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col
                    class="d-flex justify-center"
                    style="border-right: 2px solid white; border-left: 2px solid white"
                    cols="3"
                >
                    <v-row no-gutters>
                        <v-col class="d-flex justify-center pb-0" cols="12">
                            <span class="font-weight-bold" style="font-size: 15px">
                                {{ userStats.groupfitnessDone }}
                            </span>
                        </v-col>
                        <v-col class="d-flex justify-center pt-0" cols="12">
                            <span style="font-size: 12px">Kurse</span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="d-flex justify-center" cols="3">
                    <v-row no-gutters>
                        <v-col class="d-flex justify-center pb-0" cols="12">
                            <span class="font-weight-bold" style="font-size: 15px">
                                {{ Math.round(userStats.time) }} m
                            </span>
                        </v-col>
                        <v-col class="d-flex justify-center pt-0" cols="12">
                            <span style="font-size: 12px">Zeit</span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
    </v-col>
</template>

<script>
import api from '@/api'
export default {
    data() {
        return {
            userStats: {
                groupfitnessDone: 0,
                planDone: 0,
                time: 0,
            },
        }
    },
    props: {
        user: {
            type: Object,
        },
    },
    async created() {
        this.userStats = await api.fetchUserProfileStats()
    },
}
</script>