<template>
    <v-dialog
        class="workingOutStatistic overflow-hidden"
        @click:outside="$emit('close')"
        max-width="500px"
        :value="true"
        transition="dialog-bottom-transition">
        <v-card style="min-height: 50vh">
            <v-card-title
                style="word-break: break-word"
                class="pb-0 mb-0 workingOutStatistic__card-title h2 text-wrap justify-center font-weight-bold"
                >Gewicht
            </v-card-title>
            <apexchart class="ma-auto pa-5" :options="weightChartOptions" :series="weightChartItems"></apexchart>
            <v-row class="justify-content-center no-gutters">
                <v-col class="pa-2" cols="5">
                    <v-card class="d-flex fill-height flex-column rounded-xl" elevation="2">
                        <v-card-title class="pa-1 align-self-center font-weight-bold">Neu</v-card-title>
                        <v-text-field
                            label="Gewicht in kg"
                            class="align-self-center"
                            style="width: 90%"
                            v-model="newWeight"></v-text-field>
                        <v-card-actions v-if="newWeight" class="justify-center pa-0 pb-1" @click="addNewWeight"
                            ><v-btn color="primary">speichern</v-btn></v-card-actions
                        >
                    </v-card>
                </v-col>
                <v-col class="pa-2" cols="5">
                    <v-card class="d-flex fill-height flex-column rounded-xl" elevation="2">
                        <v-card-title class="pa-1 align-self-center font-weight-bold"
                            >Ziel
                            <v-btn class="ml-2" x-small v-if="targetWeight" @click="toggleTargetWeightEdit" icon
                                ><v-icon :class="targetWeightEdit ? 'red--text' : 'blue--text'"
                                    >{{ targetWeightEdit ? 'mdi-close' : 'mdi-pencil' }}
                                </v-icon></v-btn
                            ></v-card-title
                        >
                        <div class="align-self-center" v-if="targetWeight && !targetWeightEdit">
                            <number
                                class="h3 mb-0 primary--text align-self-center my-3"
                                :from="0"
                                :to="targetWeight"></number>
                            <span class="h5 primary--text"> KG</span>
                        </div>
                        <v-text-field
                            v-else
                            label="Gewicht in kg"
                            class="align-self-center"
                            style="width: 90%"
                            v-model="targetWeightTemp"></v-text-field>
                        <v-card-actions
                            v-if="targetWeightTemp && targetWeightEdit"
                            @click="addTargetWeightToUser"
                            class="justify-center pa-0 pb-1"
                            ><v-btn color="primary">speichern</v-btn></v-card-actions
                        >
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
    <!--	<v-col cols="6">
				<v-card class="d-flex flex-column pa-1 rounded-lg" @click="toggleWeightDialog()">
					<span style="margin-bottom: -20px;" class="font-weight-bold">Gewicht</span>
					<div><number class="h5 mb-0" :from="0" :to="userWeight"></number><span>KG</span></div>
				</v-card>
			</v-col> -->
</template>

<script>
import api from '@/api'
export default {
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            newWeight: null,
            targetWeight: null,
            targetWeightTemp: null,
            targetWeightEdit: false,
        }
    },
    created() {
        this.targetWeight = this.user.targetWeight
        this.targetWeightEdit = !this.targetWeight
    },
    methods: {
        toggleTargetWeightEdit() {
            this.targetWeightEdit = !this.targetWeightEdit
        },
        async addNewWeight() {
            api.addNewWeightToUser(this.newWeight)
            if (!this.user.weights) this.user.weights = []
            this.user.weights.push({ value: this.newWeight, date: this.$moment().unix() })
            this.newWeight = null
        },
        async addTargetWeightToUser() {
            if (!this.targetWeightTemp) return alert('Gib zuerst ein Gewicht ein')
            await api.addTargetWeightToUser(this.targetWeightTemp)
            this.$emit('newTargetWeight', this.targetWeightTemp)
            this.targetWeight = JSON.stringify(JSON.parse(this.targetWeightTemp))
            this.targetWeightTemp = null
            this.toggleTargetWeightEdit()
        },
    },
    computed: {
        weightChartCategories() {
            let categories = []
            if (this.user.gewicht) categories.push('Start')
            if (this.user.weights && this.user.weights.length) {
                this.user.weights.forEach((weight) => {
                    categories.push(this.$moment.unix(weight.date).format('DD.MM.YY'))
                })
            }
            if (this.targetWeight) categories.push('Ziel')
            return categories
        },
        weightChartItems() {
            let data = []
            if (this.user.gewicht) data.push(this.user.gewicht)
            if (this.user.weights && this.user.weights.length) {
                this.user.weights.forEach((weight) => {
                    data.push(weight.value)
                })
            }
            if (this.targetWeight) data.push(this.targetWeight)
            return [
                {
                    name: 'Gewicht',
                    data,
                },
            ]
        },
        weightChartOptions() {
            return {
                events: {
                    beforeResetZoom: (ctx, opt) => {
                        return {
                            xaxis: {
                                min: this.weightChartCategories.length - 3,
                                max: this.weightChartCategories.length,
                            },
                        }
                    },
                    beforeZoom: (ctx, opt) => {
                        return {
                            xaxis: {
                                min: opt.xaxis.min < 1 ? 1 : opt.xaxis.min,
                                max:
                                    opt.xaxis.max > this.weightChartCategories.length
                                        ? this.weightChartCategories.length
                                        : opt.xaxis.max,
                            },
                        }
                    },
                },
                chart: {
                    toolbar: {
                        show: true,
                        tools: {
                            download: false,
                            selection: true,
                            pan: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            reset: true,
                        },
                    },
                    type: 'area',
                },
                markers: {
                    size: 3,
                },
                forecastDataPoints: {
                    count: this.targetWeight ? 1 : 0,
                },
                xaxis: {
                    categories: this.weightChartCategories,
                    tickAmount: this.weightChartCategories.length <= 4 ? this.weightChartCategories.length : 4,
                    min: this.weightChartCategories.length - 3,
                    max: this.weightChartCategories.length,
                },
                colors: [
                    this.$vuetify.theme.themes.light.primary,
                    this.$vuetify.theme.themes.light.darkenprimary1,
                    this.$vuetify.theme.themes.light.darkenprimary2,
                ],
            }
        },
        userWeight() {
            if (this.user.gewicht) {
                return this.user.gewicht
            }
        },
    },
}
</script>

<style lang="scss" scoped></style>
