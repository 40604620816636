<template>
    <v-dialog max-width="345px" v-model="checkinCalendarActive" class="d-flex">
        <v-date-picker locale="de-CH" v-model="activeDate" :events="formattedCheckins"></v-date-picker>
        <div class="white pa-2">
            <span v-if="activeDateCheckin">
                Du hast dich an diesem Tag um {{ activeDateCheckin }} Uhr ins Studio eingecheckt.
            </span>
            <span v-else>Du hast dich an diesem Tag nicht eingecheckt.</span>
        </div>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            activeDate: this.$moment().format('YYYY-MM-DD'),
        }
    },
    props: {
        user: {
            type: Object,
        },
        checkinCalendarActive: {
            type: Boolean,
        },
    },
    computed: {
        formattedCheckins() {
            return this.user.checkins.map((checkin) => {
                return this.$moment(checkin).format('YYYY-MM-DD')
            })
        },
        activeDateCheckin() {
            let checkin = this.user.checkins.find((checkIn) => {
                return (
                    this.$moment(checkIn).format('YYYY-MM-DD') ===
                    this.$moment(this.activeDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
                )
            })

            return checkin ? this.$moment(checkin).format('HH:mm') : null
        },
    },
    watch: {
        checkinCalendarActive(val) {
            if (!val) {
                this.$emit('close')
            }
        },
    },
}
</script>
