<template>
    <v-avatar class="profile-avatar mb-3" :size="width">
        <slim-cropper :options="slimOptions" :style="'width: ' + width + 'px; height: ' + height + 'px;'">
            <img v-if="picture" :src="'/img/profilbilder' + picture" alt="" />
            <input type="file" accept="image/*" />
        </slim-cropper>
    </v-avatar>
</template>

<script>
import Slim from '@/plugins/slimImage/slim/slim.vue'
import api from '@/api'
export default {
    name: 'UserProfilepicture',
    data() {
        return {}
    },
    props: {
        picture: {
            type: String,
        },
        width: {
            type: Number,
            default: 125,
        },
        height: {
            type: Number,
            default: 125,
        },
    },
    components: { 'slim-cropper': Slim },
    methods: {
        uploadPicture(formdata, progress, success, failure, slim) {
            const formData = new FormData()
            formData.append('file', formdata[0])

            let uploadimg = {}
            uploadimg.art = 'pfp'
            uploadimg.formData = formData
            api.uploadImage(uploadimg)
                .then((data) => {
                    if (data.status) {
                        //this.oldUser.bild = data.name
                        this.$emit('updatedPicture', data.name)
                        success(data)
                    }
                })
                .catch((error) => {
                    failure(error)
                    console.log(error)
                })
        },
    },
    computed: {
        slimOptions() {
            return {
                ratio: '1:1',
                buttonCancelLabel: 'Abbrechen',
                buttonConfirmLabel: 'Bestätigen',
                'data-button-remove-label': false,
                label: 'Bild hochladen',
                'data-edit': false,
                buttonUpload: true,
                dataRemove: false,
                labelLoading: 'Bild wird hochgeladen',
                statusUploadSuccess: 'gespeichert',
                service: this.uploadPicture,
                serviceFormat: 'file',
                dataJpegCompression: 70,
                instantEdit: true,
                push: true,
                initialImage: '/img/einmalig/imageUploadPlaceholderPFP.jpg',
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.slim-btn {
    display: none;
}
</style>