<template>
    <v-card
        v-if="user && user.attendances"
        @click="openCheckinCalendar()"
        outlined
        class="fill-height white--text justify-center ma-1 primary d-flex flex-column rounded-lg align-center"
    >
        <user-check-in-calendar
            @close="checkinCalendarActive = false"
            :user="user"
            :checkinCalendarActive="checkinCalendarActive"
        ></user-check-in-calendar>
        <lottie-player
            mode="normal"
            class="settings__item-lottie"
            loop
            autoplay
            style="width: 45px; height: 45px"
            src="/img/lottiefiles/mytraining/calendar.json"
        ></lottie-player>
        <span class="h6">Check-Ins</span>
        <number
            v-if="user && user.checkins && user.checkins.length > 0"
            class="h2"
            :from="0"
            :to="user.checkins.length"
        ></number>
        <span>Seit {{ firstCheckIn }}</span>
    </v-card>
</template> 

<script>
import UserCheckInCalendar from './UserCheckInCalendar.vue'

export default {
    data() {
        return {
            checkinCalendarActive: false,
        }
    },
    components: { UserCheckInCalendar },
    props: {
        user: {
            type: Object,
        },
    },
    methods: {
        openCheckinCalendar() {
            this.checkinCalendarActive = true
        },
    },
    computed: {
        firstCheckIn() {
            if (this.user && this.user.checkins && this.user.checkins.length > 0)
                return this.$moment(this.user.checkins[0]).format('DD.MM.YYYY')
            else return ''
        },
    },
}
</script>
