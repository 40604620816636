<template>
    <v-dialog v-model="active" persistent max-width="400">
        <v-card>
            <v-card-title class="text-h5">Newsletter</v-card-title>
            <v-alert class="mx-3" type="info">
                Mit der Anmeldung zum Newsletter stimmst du der Weitergabe deiner Daten (Vorname, Nachname, Studio,
                Anrede, E-Mail) an Mailchimp zu. Weitere Details findest du in unserer Datenschutzerklärung.
            </v-alert>
            <v-select
                v-model="newsletterCenter"
                :items="center"
                label="Center"
                class="white--text v-input--is-focused px-5"
                color="primary"
            ></v-select>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="$emit('close')">Abbrechen</v-btn>
                <v-btn color="green darken-1" text @click="subscribeNewsletter">Anmelden</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import api from '@/api'
export default {
    name: 'NewsletterDialog',
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        user: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            center: [
                'Arbon',
                'Basel',
                'Bülach',
                'Dietlikon',
                'Frauenfeld',
                'Herisau',
                'Netstal',
                'Niederurnen',
                'Obfelden',
                'Oerlikon',
                'Pfungen',
                'Rikon',
                'Schwanden',
                'Sirnach',
                'St. Gallen Einstein',
                'St. Gallen Ost',
                'St. Gallen West',
                'Steckborn',
                'Wallisellen',
                'Wetzikon',
                'Winterthur',
            ],
            newsletterCenter: 'Frauenfeld',
        }
    },
    methods: {
        async subscribeNewsletter() {
            let data = {
                status: 'subscribed',
                merge_fields: {
                    MMERGE8: this.newsletterCenter,
                },
            }

            try {
                let status = await api.subscribeNewsletter(data)
                this.$toast.success('Erfolgreich für den Newsletter eingetragen')
                this.$emit('newsletterSubscribed')
            } catch (e) {
                this.$toast.error('Fehler beim Anmelden')
            }
        },
    },
    created() {},
}
</script>